@-webkit-keyframes dropDown {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes dropDown {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

#knights-tour {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  width: 100%;
}

@media screen and (max-width: 1335px) {
  #knights-tour {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

.info-card-container {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 1335px) {
  .info-card-container {
    width: 100%;
  }
}

.chess-board-container {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 1335px) {
  .chess-board-container {
    width: 100%;
  }
}

#info-card {
  width: 100%;
  margin: auto;
  -webkit-animation: 1s ease-out 0s 1 dropDown;
          animation: 1s ease-out 0s 1 dropDown;
}

#info-card .info-container {
  margin: 1em;
}

#info-card .info-container h3 {
  text-align: center;
  display: block;
  padding: 0;
  margin: 10px;
  font-size: 2em;
  font-weight: 400;
}

#info-card .info-container .description {
  font-size: 1.05em;
  line-height: 1.5em;
}

@media only screen and (max-width: 700px) {
  #info-card .info-container .description {
    font-size: 1.2em;
  }
}

#info-card .info-container .tour-status {
  text-align: center;
  display: block;
  font-size: 1.2em;
  color: #7aaf3c;
  font-weight: 600;
}

#info-card .info-container .button-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #303030;
  font-size: 1.05em;
  font-weight: 500;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  font-family: "exo", sans-serif;
  width: 200px;
  height: 40px;
  padding: 10px;
  margin: 0.5em;
  color: #ffffff;
  border: 1px solid #303030;
  border-radius: 0.5em;
}

@media only screen and (max-width: 700px) {
  button {
    font-size: 1.2em;
  }
}

button.restart:not(.disabled) {
  background: url(../assets/images/icons8-restart-24.png) no-repeat 95% #303030;
}

button.computer-mode.step-through {
  background: url(../assets/images/icons8-end-single-24.png) no-repeat 95% #303030;
}

button.undo:not(.disabled).step-through {
  background: url(../assets/images/icons8-end-24.png) no-repeat 95% #303030;
}

button.undo:not(.disabled).step-through:hover {
  font-size: 1.1em;
  background-color: #7aaf3c;
  border-color: #7aaf3c;
  -webkit-box-shadow: 0 0 0.5em #7aaf3c;
          box-shadow: 0 0 0.5em #7aaf3c;
}

button.undo:not(.disabled):not(.step-through) {
  background: url(../assets/images/icons8-undo-24.png) no-repeat 95% #303030;
}

button.undo:not(.disabled):not(.step-through):hover {
  font-size: 1.1em;
  background-color: #7aaf3c;
  border-color: #7aaf3c;
  -webkit-box-shadow: 0 0 0.5em #7aaf3c;
          box-shadow: 0 0 0.5em #7aaf3c;
}

button.undo.disabled {
  visibility: hidden;
}

button:not(.undo).disabled {
  background-color: #cacaca;
  border-color: #cacaca;
  color: #777777;
}

button:not(.disabled):hover {
  font-size: 1.1em;
  background-color: #7aaf3c;
  border-color: #7aaf3c;
  -webkit-box-shadow: 0 0 0.5em #7aaf3c;
          box-shadow: 0 0 0.5em #7aaf3c;
}

#board {
  border: 1;
  margin: auto;
  border-style: solid;
  border-color: #656d4a;
  background-color: #c2c5aa;
  border-radius: 0.25em;
  width: 40em;
  height: 40em;
  -webkit-animation: 1s ease-out 0s 1 fadeInSlideUp;
          animation: 1s ease-out 0s 1 fadeInSlideUp;
  display: -moz-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media screen and (max-width: 736px) {
  #board {
    width: 96vw;
    height: 96vw;
  }
}

.square {
  width: 5em;
  height: 5em;
  margin: 0em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #181818;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.square.white {
  background-color: #d0d2c2;
}

.square.black {
  background-color: #5f664a;
}

.square.highlight {
  background-color: #7aaf3c;
}

.square.highlight:hover {
  border: 2px solid #f4f0f0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.square.clickable:hover {
  border: 2px solid #f4f0f0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.square img {
  width: 3em;
  height: 3em;
}

.square p {
  font-size: 2em;
  font-weight: 500;
  color: #181818;
  font-family: "exo", sans-serif;
}

@media screen and (max-width: 736px) {
  .square {
    width: 12vw;
    height: 12vw;
  }
  .square img {
    width: 2.5em;
    height: 2.5em;
  }
  .square p {
    font-size: 1.5em;
  }
}
/*# sourceMappingURL=Components.css.map */