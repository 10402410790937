@import url(./Components.css);
#App {
  background-color: #ffffff;
  font-family: "Raleway", sans-serif;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

#App * {
  overflow-wrap: break-word;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

@media screen and (max-width: 1680px) {
  #App {
    font-size: 13pt;
  }
}

@media screen and (max-width: 1280px) {
  #App {
    font-size: 12.5pt;
  }
}

@media screen and (max-width: 736px) {
  #App {
    font-size: 12pt;
  }
}

@media screen and (max-width: 360px) {
  #App {
    font-size: 11pt;
  }
}

#footer {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  margin: auto;
  width: 100%;
  min-height: 2em;
  padding: 10px;
}

#footer .footer-item {
  width: 100%;
  margin: 10px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: distribute;
      align-content: space-around;
}

#footer .footer-item img {
  width: 24px;
  height: 24px;
  margin: 0px 5px;
}

#footer .footer-item img:hover {
  -webkit-filter: invert(58%) sepia(64%) saturate(418%) hue-rotate(45deg) brightness(95%) contrast(88%);
          filter: invert(58%) sepia(64%) saturate(418%) hue-rotate(45deg) brightness(95%) contrast(88%);
  translate: 0px -5px;
}

#footer .footer-item .text-link {
  font-size: 0.9em;
  color: #303030;
  margin: 5px 10px;
}

#footer .footer-item .text-link:hover {
  color: #7aaf3c;
  font-weight: 500;
  translate: 0px -5px;
}
/*# sourceMappingURL=App.css.map */